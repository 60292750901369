@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
    background-color: #F7EFE2;
    font-family: Poppins;
}

li {
    color: #6D6D6D;
}

.float {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 40px;
    right: 40px;
    background-color: #25d366;
    color: #FFF;
    border-radius: 50px;
    text-align: center;
    font-size: 30px;
    box-shadow: 2px 2px 3px #999;
    z-index: 100;
}

.my-float {
    margin-top: 16px;
}

.divider {
    width: 1.5px;
    height: 32px;
    background-color: #ccc;
    margin: 0 10px;
}

.header {
    color: #000;
    text-align: center;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.header-button {
    background-color: #FFA300;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    color: white
}



.image-container {
    flex-shrink: 0;
}

.box {
    flex-shrink: 0;
    border-radius: 16px;
    background: #FFF;
}


.icon {
    flex-shrink: 0;
}

.p2 {
    color: #000;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.p3 {
    color: #000;
    font-family: Poppins;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.scrollbar-product::-webkit-scrollbar-thumb {
    background-color: #ff8c00;
    border-radius: 5px;
}

.scrollbar-product::-webkit-scrollbar-thumb:hover {
    background-color: #cc7000;
}

.scrollbar-product::-webkit-scrollbar-track {
    background-color: #FFA300;
}

.scrollbar-product::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: #FFA300;
}